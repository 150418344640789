<template>
  <div class="sm:my-6 my-3 flex items-center justify-between border-b pb-3">
    <div>
      <h2 class="text-xl font-semibold">{{ title }}</h2>
    </div>
  </div>

  <div class="relative -mt-3">
    <div class="grid grid-cols-2 md:grid-cols-6" v-if="peoples.data.length">
      <PeopleItem v-for="people in peoples.data" :people="people" :key="'people' + people.id" :prefix="prefix"/>
    </div>
    <div class="mt-4">
      <v-pagination
          class="justify-content-center"
          v-if="peoples.paginatorInfo.lastPage > 1"
          v-model="peoples.paginatorInfo.currentPage"
          :pages="peoples.paginatorInfo.lastPage"
          :range-size="1"
          active-color="#DCEDFF"
          @update:modelValue="loadPeoples"
      />
    </div>
  </div>
</template>

<script>
import ApiService from "../../core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import PeopleItem from "./PeopleItem";
import {getNameFromPeoplePrefix, getPluralFromPeoplePrefix} from "../../core/services/utils.service";

export default {
  name: 'PeopleList',
  components: {
    VPagination,
    PeopleItem
  },
  data() {
    return {
      title: "",
      prefix: "",
      type: "",
      peoples: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      }
    }
  },
  methods: {
    loadPeoples() {
      let plural = getPluralFromPeoplePrefix(this.prefix);
      let plurals = plural + "s";
      let where = null;
      if (this.type) {
        let filterType = this.type === "viet-nam" ? "domestic" : "foreign";
        where = {
          AND: [
            {
              column: "type",
              value: filterType
            }
          ]
        };
      }

      let query = `query($page: Int, $where: WhereConditions) {
        ${plurals}(first: 30, page: $page, orderBy: [{column: "title", order: ASC}], where: $where) {
          data {
            id
            slug
            title
            avatar {
              url
            }
          },
          paginatorInfo {
            currentPage
            lastPage
            total
          }
        }
      }`;

      ApiService.graphql(query, {page: this.peoples.paginatorInfo.currentPage, where: where})
          .then(({data}) => {
            if (data.data && data.data[plurals]) {
              this.peoples = data.data[plurals];
            }
          })
          .catch((response) => {
            console.log(response);
          });
    },
    reRender() {
      this.title = getNameFromPeoplePrefix(this.prefix, this.type);
      this.loadPeoples();
    }
  },
  mounted() {
    this.prefix = this.$route.params.prefix;
    this.type = this.$route.params.type;
    this.reRender();
  },
  beforeRouteUpdate(to, from, next) {
    this.prefix = to.params.prefix;
    this.type = to.params.type;
    this.reRender();
    window.scrollTo(0, 0);
    next();
  }
}
</script>
