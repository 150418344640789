<template>
  <div class="p-2 justify-center flex text-center">
    <router-link :to="{name: 'PeopleDetail', params: {prefix: prefix, slug: people.slug}}" :title="people.title">
      <img :src="people.avatar ? people.avatar.url : 'https://static.bcdcnt.net/assets/images/avatars/avatar-1.jpg'" class="h-32 w-32 object-cover rounded-full shadow-sm">
      <div class="py-3">
        <h4 class="text-base font-semibold mb-0.5 line-clamp-1">{{ people.title }}</h4>
      </div>
    </router-link>
  </div>
</template>

<script>

export default {
  name: "PeopleItem",
  props: {
    prefix: String,
    people: {
      type: Object,
      default() {
        return {
          title: ""
        };
      }
    }
  }
}
</script>
